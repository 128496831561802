import {
  getCustomerFriendlyErrorMessageKey,
  getErrorCode,
  isUpptackaErrorId,
} from "@/utils/errors"
import Button from "@ingka/button"
import refreshIcon from "@ingka/ssr-icon/paths/arrow-counterclockwise"
import React from "react"
import { useTranslation } from "react-i18next"

interface Props {
  error: Error
}

export const ErrorView: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation()

  return (
    <div
      data-cy="error-view"
      className="absolute inset-0 flex h-full w-full flex-col justify-between"
    >
      <div className="flex flex-col px-6 py-12">
        <h3 className="text-2xl leading-normal">
          {t("Interstitial-ErrorView-title")}
        </h3>
        <p className="mt-6 text-sm leading-normal">
          {t(
            (isUpptackaErrorId(error.name) &&
              getCustomerFriendlyErrorMessageKey(error.name)) ||
              "Interstitial-ErrorView-message",
          )}
        </p>
        <p className="text-sm leading-normal text-gray-500">
          {"Error " + getErrorCode(error.name)}
        </p>
      </div>
      <div className="flex border border-t border-gray-200 p-6">
        <Button
          className="w-full"
          type="primary"
          ssrIcon={refreshIcon}
          text={t("Interstitial-ErrorView-refresh-button")}
          onClick={() => window.location.reload()}
        />
      </div>
    </div>
  )
}
