import { Trans, useTranslation } from "react-i18next"
import { FullServeStatus } from "./FullServeStatusText"
import classNames from "classnames"

interface OrderCardInformationProps {
  status?: FullServeStatus
  orderPaidDate?: string
  estimatedReadyTime?: number
  location?: "left" | "center"
  isWaitingTimeEnabled: boolean
}

export const OrderCardInformation: React.FC<OrderCardInformationProps> = ({
  status,
  orderPaidDate,
  estimatedReadyTime,
  location,
  isWaitingTimeEnabled,
}) => {
  const { t } = useTranslation()

  const informationText = (() => {
    switch (status) {
      case "WAITING_FOR_PAYMENT":
        return t("fullserve.scan-barcode-at-closest-payment-point")

      case "PREPARING_ORDER":
        return t(
          isWaitingTimeEnabled && estimatedReadyTime
            ? "mobile.estimated-collection-time"
            : "mobile.when-ready-collect-order",
        )
      case "READY_FOR_PICKUP":
        return t("mobile.approach-pick-up-point-order-ready")
      case "COLLECTED":
        return t("mobile.thanks-for-shopping")
      default:
        return t("mobile.checking-order-status")
    }
  })()

  return (
    <div
      className={classNames("flex flex-col", {
        "items-start": location === "left",
        "items-center text-center": location === "center",
      })}
    >
      <p className="text-sm">
        <Trans i18nKey={informationText} />
        {status === "WAITING_FOR_PAYMENT" &&
          isWaitingTimeEnabled &&
          estimatedReadyTime && (
            <p className="text-sm pt-6">
              {" "}
              <Trans
                i18nKey={t("mobile.items-will-be-ready", {
                  estimatedWaitingTime: estimatedReadyTime,
                })}
              />
            </p>
          )}
      </p>
      <EstimatedWaitingTime
        status={status}
        estimatedReadyTime={estimatedReadyTime}
        orderPaidDate={orderPaidDate}
      />
    </div>
  )
}

interface OrderDetailsInformationProps {
  status?: FullServeStatus
  orderPaidDate?: string
  estimatedReadyTime?: number
  location?: "left" | "center"
  isWaitingTimeEnabled: boolean
}

export const OrderDetailsInformation: React.FC<
  OrderDetailsInformationProps
> = ({
  status,
  orderPaidDate,
  estimatedReadyTime,
  location,
  isWaitingTimeEnabled,
}) => {
  const { t } = useTranslation()

  const informationText = (() => {
    switch (status) {
      case "WAITING_FOR_PAYMENT":
        return `${t("mobile.scan-to-pay")} ${t(
          isWaitingTimeEnabled && estimatedReadyTime
            ? "mobile.items-will-be-ready"
            : "mobile.start-preparing-order-bold",
          {
            estimatedWaitingTime: estimatedReadyTime,
          },
        )}`

      case "PREPARING_ORDER":
        return t(
          isWaitingTimeEnabled && estimatedReadyTime
            ? "mobile.estimated-collection-time"
            : "mobile.when-ready-collect-order",
        )
      case "READY_FOR_PICKUP":
        return t("mobile.collect-order")
      case "COLLECTED":
        return ""
      default:
        return t("mobile.checking-order-status")
    }
  })()

  return (
    <div
      className={classNames("flex flex-col", {
        "items-start": location === "left",
        "items-center text-center": location === "center",
      })}
    >
      <p className="text-sm">
        <Trans i18nKey={informationText} />
      </p>
      <EstimatedWaitingTime
        status={status}
        estimatedReadyTime={estimatedReadyTime}
        orderPaidDate={orderPaidDate}
      />
    </div>
  )
}

const EstimatedWaitingTime = ({
  status,
  orderPaidDate,
  estimatedReadyTime,
}: {
  status?: FullServeStatus
  orderPaidDate?: string
  estimatedReadyTime?: number
}) => {
  const pickupTime =
    orderPaidDate && estimatedReadyTime
      ? new Date(
          new Date(orderPaidDate).getTime() + estimatedReadyTime * 60 * 1000,
        )
      : undefined

  const pickupTimeInMinutes = pickupTime?.getMinutes()
  const formattedMinutes = pickupTimeInMinutes
    ? pickupTimeInMinutes < 10
      ? `0${pickupTimeInMinutes}`
      : `${pickupTimeInMinutes}`
    : undefined

  return (
    status === "PREPARING_ORDER" &&
    pickupTime &&
    formattedMinutes && (
      <p className="text-blue-brand font-bold text-base">
        {pickupTime.getHours() + ":" + formattedMinutes}
      </p>
    )
  )
}
