import { ReactElement, useEffect, useState } from "react"
import { useCreateList } from "@/hooks/useCreateList"
import useVisit, { EntryPoint } from "@/hooks/useVisit"
import { sendNonUpptackaOrderEvent } from "@/analytics/events/sendNonUpptackaOrderEvent"
import { sendStartSessionEvent } from "@/analytics/events/sendStartSessionEvent"
import { useGetOrder } from "@/hooks/useGetOrder"
import { useListId, useParseHandover } from "@/hooks/useHandoverQuery"
import { useIsOnline } from "@/hooks/useIsOnline"
import { NoServiceError } from "@/utils/errors"
import { useParams, useSearchParams } from "react-router"
import { useNavigateHandover } from "@/hooks/useNavigateHandover"
import { useMergeLists } from "@/hooks/useMergeLists"
import { useListigQuery } from "@/hooks/useListigQuery"
import { useCcOrder } from "@/hooks/useCcOrder"
import LoadingView from "@/views/LoadingView"
import { useLoadingSteps } from "@/hooks/useLoadingSteps"
import { getActiveList } from "@/utils/localStorage"

/**
 * Display a loading view while setting up a listig list.
 */
export const ListSetup: React.FC<{
  entryPoint: EntryPoint
  children?: ReactElement
}> = ({ entryPoint, children }) => {
  const [searchParams] = useSearchParams()
  const orderFastTrack = searchParams.get("orderFastTrack")
  const storeNo = searchParams.get("storeNo") ?? searchParams.get("s")
  const { market, language } = useParams<{ market: string; language: string }>()
  const { parseHandover } = useParseHandover()
  const [handoverCompleted, setHandoverCompleted] = useState(false)
  const [listHandoverCompleted, setListHandoverCompleted] = useState(false)
  const [orderNo, setOrderNo] = useState<string | undefined>(undefined)
  const [orderNoSource, setOrderNoSource] = useState<string | undefined>(
    undefined,
  )
  const { isOnline, isLoading: isOnlineLoading } = useIsOnline(storeNo)
  const { setEntryPoint, setVisitedPage, setVisitCounter, clearVisitedPage } =
    useVisit()
  const createList = useCreateList(entryPoint)
  const {
    mergeLists,
    loading: listsLoading,
    completed: listSetupCompleted,
  } = useMergeLists()
  const { navigateToPip, navigateToList } = useNavigateHandover(
    market,
    language,
  )
  const { createCcOrder, isOrderCreationLoading } = useCcOrder()
  const [fastTrackOrderCreated, setFastTrackOrderCreated] = useState(false)
  const { steps } = useLoadingSteps(undefined, true)

  const listIdFromLocalStorage = getActiveList()

  const listIdFromUrl = useListId()?.listId
  const listFromUrl = useListigQuery(listIdFromUrl)

  const listIdsAreTheSame = listIdFromUrl === listIdFromLocalStorage

  useEffect(() => {
    setEntryPoint(entryPoint)
    //eslint-disable-next-line
  }, [])

  // Fetch the order and check the status
  const {
    order,
    error: getOrderError,
    isLoading: getOrderLoading,
    isFetched: getOrderFetched,
  } = useGetOrder(
    orderNo,
    orderNoSource,
    market,
    language,
    storeNo ?? undefined,
  )

  const createNewList = async () =>
    createList({
      market,
      language,
      isOnline: !!isOnline,
    })

  useEffect(() => {
    if (entryPoint !== "list") return
    if (
      listSetupCompleted ||
      listsLoading ||
      listHandoverCompleted ||
      (orderFastTrack && isOrderCreationLoading)
    )
      return

    if (listFromUrl.list && !listIdsAreTheSame) {
      sendStartSessionEvent(
        listFromUrl.list.metadata.createdBy.system ?? "unknown",
        "list_handover",
        setVisitCounter,
        listFromUrl.list.metadata.createdBy.user,
        undefined,
        listIdFromUrl,
        !!orderFastTrack,
      )
    }

    if (orderFastTrack && !fastTrackOrderCreated) {
      if (listFromUrl.list && !listIdsAreTheSame) {
        clearVisitedPage("fullServeOnboarding")
        createCcOrder({
          onSuccess: () => setFastTrackOrderCreated(true),
          onError: () => setFastTrackOrderCreated(true),
        })
      } else {
        setFastTrackOrderCreated(true)
      }
    } else {
      setListHandoverCompleted(true)
      mergeLists({
        navigate: navigateToList,
        itemsToMoveToActiveList: listFromUrl.list?.items,
        ordersToMoveToActiveList: listFromUrl.list?.orders,
        handoverStoreNo: listFromUrl.list?.businessUnit.code,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listsLoading, isOrderCreationLoading, fastTrackOrderCreated])

  useEffect(() => {
    if (entryPoint !== "order") return

    const handover = parseHandover(entryPoint)
    setOrderNo("orderNo" in handover ? handover.orderNo : undefined)
    setOrderNoSource(
      "orderNoSource" in handover ? handover.orderNoSource : undefined,
    )

    if (
      listsLoading ||
      isOnlineLoading ||
      handoverCompleted ||
      getOrderLoading ||
      !getOrderFetched
    )
      return

    setHandoverCompleted(true)

    if (getOrderError || order?.orderStatus === "CANCELLED") {
      throw new NoServiceError("INVALID_ORDER")
    }

    setVisitedPage("fullServeOnboarding")
    sendNonUpptackaOrderEvent(
      order?.orderNo ?? "",
      order?.orderCreationClientSystem ?? "",
    )

    mergeLists({
      navigate: navigateToList,
      createNewList,
      ordersToMoveToActiveList: order
        ? [
            {
              orderNo: order.orderNo,
              orderNoSource: order.orderNoSource,
              orderDate: order.orderCreationDate ?? "",
              items: order.items?.map((item) => ({
                id: item.info.no,
                type: item.info.type as "ART" | "SPR",
                quantity: item.quantity,
              })),
            },
          ]
        : undefined,
      handoverStoreNo: handover.storeNo,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    listsLoading,
    getOrderLoading,
    getOrderFetched,
    isOnlineLoading,
    handoverCompleted,
  ])

  useEffect(() => {
    if (entryPoint === "list" || entryPoint === "order") return
    if (listsLoading || isOnlineLoading || handoverCompleted) return

    const handover = parseHandover(entryPoint)
    setHandoverCompleted(true)

    switch (entryPoint) {
      case "new":
        sendStartSessionEvent(
          handover.source,
          "empty_list",
          setVisitCounter,
          undefined,
          handover.sessionId ?? undefined,
        )
        mergeLists({
          navigate: navigateToList,
          createNewList,
          handoverStoreNo: handover.storeNo,
        })
        break

      case "items":
        sendStartSessionEvent(
          handover.source,
          "item_handover",
          setVisitCounter,
          undefined,
          handover.sessionId ?? undefined,
        )
        mergeLists({
          navigate: navigateToList,
          createNewList,
          itemsToMoveToActiveList: "items" in handover ? handover.items : [],
          handoverStoreNo: handover.storeNo,
        })
        break

      case "product":
        sendStartSessionEvent(
          handover.source,
          "product_handover",
          setVisitCounter,
          undefined,
          handover.sessionId ?? undefined,
        )
        mergeLists({
          navigate: (listId: string) =>
            navigateToPip(
              listId,
              "id" in handover ? handover.id : undefined,
              "type" in handover ? handover.type : undefined,
            ),
          createNewList,
          handoverStoreNo: handover.storeNo,
        })
        break
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listsLoading, isOnlineLoading, handoverCompleted])

  if (children && listSetupCompleted) {
    return children
  } else {
    return <LoadingView type="linear" steps={steps} />
  }
}
