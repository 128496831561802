import { FeedbackData } from "@/components/list/feedback/FeedbackView"
import { fetchWithErrorHandling } from "@/utils/fetch"

export async function sendFeedback(data: FeedbackData, authToken: string) {
  await fetchWithErrorHandling(
    "BUY_SEND_FEEDBACK",
    `${process.env.BUY_API_URL}/feedback`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
      body: JSON.stringify(data),
    },
  )
}
