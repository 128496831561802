import { useTranslation } from "react-i18next"
import InlineMessage from "@ingka/inline-message"
import { FullServeError } from "@/utils/fullServeRestrictions"
import React from "react"
import { FULLSERVE_ERRORS } from "@/types/errors"

interface FullServeErrorProps {
  errorMessage: FullServeError | null
}

const FullServeErrorMessage: React.FC<FullServeErrorProps> = (props) => {
  const { t } = useTranslation()
  const errorCode = props.errorMessage
    ? FULLSERVE_ERRORS[props.errorMessage].code
    : undefined

  return (
    <>
      {!!props.errorMessage && (
        <InlineMessage
          data-cy="fullserve-error-message"
          prefix="text-sm "
          title={
            props.errorMessage === "LOW_STOCK"
              ? t("mobile.low-stock.title")
              : props.errorMessage === "API_ERROR" ||
                  props.errorMessage === "UNKNOWN_STORE"
                ? t("Interstitial-FullServe-warning-title")
                : t("common.contact-coworker")
          }
          body={
            (props.errorMessage === "LOW_STOCK"
              ? t("mobile.low-stock.text")
              : t("Interstitial-fullserve-error-fallback")) +
            (errorCode ? ` [${errorCode}]` : "")
          }
          variant={"cautionary"}
          className="mx-6"
        />
      )}
    </>
  )
}

export default FullServeErrorMessage
