import { SeverityLevel } from "@sentry/types"
import { ProductError } from "./responses/find"
import { FullServeError } from "@/utils/fullServeRestrictions"

export const upptackaErrorIds = [
  "INVALID_STORE",
  "INVALID_MARKET",
  "INVALID_LANGUAGE",
  "INCOMPLETE_HANDOVER",
  "EXPIRED_HANDOVER",
  "EXPIRED_LIST",
  "OAUTH_TOKEN_FAILED",
  "MISSING_STORE_INFORMATION",
  "PRODUCT_INFO_FAILED",
  "LISTIG_FAILED",
  "ADMIN_SETTINGS_FAILED",
  "INVALID_LISTIG_LIST_ID",
  "INVALID_ORDER",
] as const

export type UpptackaErrorId = (typeof upptackaErrorIds)[number]

export type UpptackaApiErrorName =
  | "ADMIN_SETTINGS"
  | "AUTH"
  | "EXPLORE"
  | "FIND"
  | "FIND_GET_VARIANTS"
  | "KORTIS"
  | "BUY_ORDER_RETRIEVAL"
  | "BUY_ORDER_CREATION"
  | "BUY_ORDER_CANCEL"
  | "BUY_ORDER_MODIFY"
  | "BUY_SEND_FEEDBACK"
  | "BUY_MULTIPLE_ORDER_RETRIEVAL"
  | "BUY_GET_TIME_SLOTS"
  | "BUY_UPDATE_TIME_SLOT"
  | "LISTIG_CREATION"
  | "LISTIG_ADD"
  | "LISTIG_ADD_ORDER"
  | "LISTIG_DELETE"
  | "LISTIG_GET"
  | "LISTIG_UPDATE"
  | "OAUTH_TOKEN"
  | "KEPS"
  | "GET_COMPLETE_ADDRESS"

export type NoServiceRefreshInterval =
  | 10000
  | 15000
  | 30000
  | 60000
  | 1000000000

type UpptackaError = {
  code: string
  message?: string
  refreshInterval?: NoServiceRefreshInterval
  friendlyMessageKey?: string
  logSeverity?: SeverityLevel
}

type UpptackaFullServeError = {
  code: string
}

const expiredHandover = {
  message: "Expired handover link.",
  friendlyMessageKey: "Interstitial-ErrorView-expired-subtitle",
  logSeverity: "info" as SeverityLevel,
}

export const FULLSERVE_ERRORS: Record<FullServeError, UpptackaFullServeError> =
  {
    DISABLED: {
      code: "F001",
    },
    DISABLED_RANGE: {
      code: "F002",
    },
    LOW_STOCK: {
      code: "F003",
    },
    NO_TIME_SLOT: {
      code: "F004",
    },
    API_ERROR: {
      code: "F005",
    },
    UNKNOWN_STORE: {
      code: "F006",
    },
  }

export const NO_SERVICE_ERRORS: Record<UpptackaErrorId, UpptackaError> = {
  INVALID_STORE: {
    code: "0005",
    message: "Invalid business unit.",
    refreshInterval: 60000,
  },
  INVALID_MARKET: {
    code: "0006",
    message: "Invalid market.",
  },
  INVALID_LANGUAGE: {
    code: "0007",
    message: "Invalid language.",
  },
  INCOMPLETE_HANDOVER: {
    code: "0008",
    message: "Handover is missing one or several required fields.",
    logSeverity: "warning",
  },
  EXPIRED_HANDOVER: {
    code: "0009",
    ...expiredHandover,
  },
  EXPIRED_LIST: {
    code: "0010",
    ...expiredHandover,
  },
  MISSING_STORE_INFORMATION: {
    code: "0013",
    message: "Invalid Store information.",
  },
  OAUTH_TOKEN_FAILED: {
    code: "0014",
    message: "Failed to retrieve oAuth token.",
    refreshInterval: 15000,
    logSeverity: "fatal",
  },
  PRODUCT_INFO_FAILED: {
    code: "0015",
    message: "Failed to fetch product information.",
  },
  LISTIG_FAILED: {
    code: "0016",
    message: "Failed to fetch listig list.",
  },
  ADMIN_SETTINGS_FAILED: {
    code: "0017",
    message: "Failed to fetch settings",
  },
  INVALID_LISTIG_LIST_ID: {
    code: "0018",
    message: "Invalid list id.",
    logSeverity: "warning",
  },
  INVALID_ORDER: {
    code: "0019",
    message: "Invalid order.",
    logSeverity: "warning",
  },
}

/**
 * Custom error representing a product error.
 */
export class ProductInfoError extends Error {
  /**
   * The product error encountered.
   */
  error: ProductError
  /**
   * Custom error representing a product error.
   * @param error The product error encountered.
   */
  constructor(error: ProductError) {
    super(error.error.message)
    this.name = "Product Error"
    this.error = error
  }
}
