import {
  FullServeProduct,
  OnlineProduct,
} from "@/types/product/categorizedProduct"
import { useAuth } from "./useAuth"
import { useLocale } from "./useLocale"
import { useQueryWithErrorHandling } from "./useReactQuery"
import { formatCcOrderItems } from "@/utils/formatCcOrderItems"
import { useTimeSlotManagement } from "./featureFlags/useTimeSlotManagement"
import { getDetailedTimeSlots } from "@/api/getDetailedTimeSlots"

export const useGetDetailedTimeSlots = (
  buCode?: string,
  items?: (FullServeProduct | OnlineProduct)[],
  enabled = true,
) => {
  const { oAuthToken } = useAuth()
  const { market, language } = useLocale()
  const timeSlotManagementEnabled = useTimeSlotManagement()

  const formattedItems = formatCcOrderItems(items ?? []).map((item) => ({
    itemId: item.itemId,
    quantity: item.quantity,
  }))

  const timeSlotsQuery = useQueryWithErrorHandling(
    [buCode, formattedItems, market, language, "detailed"],
    () =>
      oAuthToken && buCode && formattedItems
        ? getDetailedTimeSlots(
            market,
            language,
            oAuthToken,
            buCode,
            formattedItems,
          )
        : Promise.reject("Missing data"),
    {
      enabled:
        enabled &&
        timeSlotManagementEnabled &&
        !!oAuthToken &&
        !!buCode &&
        !!formattedItems &&
        !!formattedItems.length,
      gcTime: Infinity,
      staleTime: Infinity,
    },
    "error",
  )

  return { data: timeSlotsQuery.data, isLoading: timeSlotsQuery.isFetching }
}
