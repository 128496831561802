import { useAuth } from "@/hooks/useAuth"
import { useListig } from "@/hooks/useListig"
import { useStoreInformation } from "@/hooks/useStoreInformation"
import { useListId } from "./useHandoverQuery"
import { getActiveList } from "@/utils/localStorage"

/**
 * Reads global states to determine the loading progression
 * @returns steps for usage in linear LoadingView
 */
export const useLoadingSteps = (
  loadingSteps?: { completionStep: boolean; loadingText: string }[],
  showInOrder = false,
) => {
  const defaultLoadingSteps = useDefaultLoadingSteps()
  const steps = loadingSteps ?? defaultLoadingSteps

  const nbrCompletedSteps = steps.reduce(
    (count, step) => count + Number(step.completionStep),
    0,
  )

  const loadInfoSteps = steps.map((step, i) => ({
    title: step.loadingText,
    completed: showInOrder
      ? steps.slice(0, i + 1).every((step) => step.completionStep)
      : i < nbrCompletedSteps,
  }))

  return {
    steps: loadInfoSteps,
    completed: loadInfoSteps.every((step) => step.completed),
  }
}

const useDefaultLoadingSteps = () => {
  const { oAuthToken } = useAuth()
  const { list, isLoading: isListLoading } = useListig()
  const { isLoading: isStoreInfoLoading } = useStoreInformation(
    list?.businessUnit.code ?? null,
  )

  const listIdFromUrl = useListId()?.listId
  const listIdFromLocalStorage = getActiveList()

  return [
    {
      completionStep: !!oAuthToken,
      loadingText: "Reading manual",
    },
    {
      completionStep: listIdFromUrl === listIdFromLocalStorage,
      loadingText: "Assembling list",
    },
    {
      completionStep: !isListLoading,
      loadingText: "Counting screws",
    },
    {
      completionStep: !isStoreInfoLoading,
      loadingText: "Locating store",
    },
  ]
}
