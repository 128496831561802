import {
  CHECKOUT_ENABLED,
  CHECKOUT_ENABLED_KIOSKS,
  TSM_ENABLED,
} from "@/config/constants"
import { useListig } from "@/hooks/useListig"
import { isEnabledWithConstraints } from "@/utils/isEnabled"
import { useAdminSettings } from "../useAdminSettings"
import { useIsomOrders } from "./useIsomOrders"

/**
 * @returns true if checkout has been enabled for market/store/kiosk
 */
export function useCheckout() {
  const { session } = useListig()
  const { settings } = useAdminSettings()
  const { source } = session
  const ukid = source?.type === "kiosk" ? source.ukid : undefined
  const isIsom = useIsomOrders()

  return isEnabledWithConstraints({
    overrides: [TSM_ENABLED, CHECKOUT_ENABLED], // will be automatically enabled if tsm or checkout is enabled from url
    dependancies: [isIsom], // requires isom to be enabled
    locations: [
      !!settings?.isomRollout.enableCheckoutOrderCreation,
      !!ukid && CHECKOUT_ENABLED_KIOSKS.includes(ukid.toUpperCase()),
    ],
  })
}
