import { deleteListigList } from "@/api/listig"
import { useAuthAsync } from "@/hooks/useAuth"
import { useMutationWithErrorHandling } from "./useReactQuery"
import { useCallback } from "react"

/**
 * Provides a function to delete a list,
 * @returns a function to delete a list
 */
export function useDeleteList() {
  const { getAuthToken } = useAuthAsync()

  /** mutation to delete a list */
  const mutation = useMutationWithErrorHandling(
    async (input: { listId: string | undefined }) => {
      const oAuthToken = await getAuthToken()

      return input.listId && oAuthToken
        ? deleteListigList(input.listId, oAuthToken)
        : Promise.reject(new Error("Missing fields"))
    },
  )

  const mutate = mutation.mutateAsync
  const deleteList = useCallback(
    (
      listId: string | undefined,
      options?: {
        onSuccess?: () => void
      },
    ) => mutate({ listId }, { onSuccess: options?.onSuccess }),
    [mutate],
  )

  return {
    /** delete a list */
    deleteList,
  }
}
