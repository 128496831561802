import { getProductInformation } from "@/api/getProductInformation"
import { useAuth } from "@/hooks/useAuth"
import { useLocale } from "@/hooks/useLocale"
import { useListig } from "@/hooks/useListig"
import { ProductsV2Response } from "@/types/responses/find"
import { useIsOnline } from "./useIsOnline"
import { useQueryWithErrorHandling } from "./useReactQuery"
import { ListigItem } from "@/types/listig"
import { createProductList } from "@/utils/createProductList"
import { keepPreviousData } from "@tanstack/react-query"
import { PipProduct } from "@/types/product/pip"
import { ProductType } from "@/types/product/products"

const useProductHook = (productNo: string | undefined, type: ProductType) => {
  const { oAuthToken } = useAuth()
  const { language, market } = useLocale()
  const { session } = useListig()
  const { businessUnitCode } = session
  const { isOnline, isLoading } = useIsOnline(businessUnitCode ?? null)

  const productsQuery = useQueryWithErrorHandling<
    ProductsV2Response,
    Error,
    PipProduct
  >(
    ["product", productNo, type],
    () =>
      oAuthToken && language && businessUnitCode && productNo && !isLoading
        ? getProductInformation(
            market,
            language,
            isOnline
              ? { code: market, type: "ru" }
              : { code: businessUnitCode, type: "sto" },
            [{ id: productNo, type }],
            oAuthToken,
          )
        : Promise.reject(new Error("Missing list or token")),
    {
      enabled: !!oAuthToken && !!productNo && !!businessUnitCode && !isLoading,
      gcTime: Infinity,
      staleTime: 60000, // 10 minutes
      retry: 3,
      select: (data) => {
        const first = data?.[0]
        if (!first || "error" in first) {
          throw new Error("No product")
        } else {
          return first
        }
      },
    },
  )

  return {
    productInfo: productsQuery.data,
    error: productsQuery.error,
    isLoading: productsQuery.isPending,
  }
}

// This is needed for stub in cypress component tests
export const productObject = { useProduct: useProductHook }
export const useProduct = (productNo: string | undefined, type: ProductType) =>
  productObject.useProduct(productNo, type)

export const useProducts = (buCode?: string, items?: ListigItem[]) => {
  const { oAuthToken } = useAuth()
  const { market, language } = useLocale()
  const { isOnline, isLoading } = useIsOnline(buCode ?? null)

  // filter items array to only contain unique items and update quantity for any duplicates
  const uniqueItems = items?.reduce(
    (items: ListigItem[], current: ListigItem) => {
      const duplicate = items.find((item) => item.id === current.id)

      if (duplicate) {
        duplicate.quantity += current.quantity
      } else {
        items.push(current)
      }

      return items
    },
    [],
  )

  const productsQuery = useQueryWithErrorHandling<
    ProductsV2Response,
    Error,
    ReturnType<typeof createProductList>
  >(
    ["products", ...(uniqueItems ?? "no_items")],
    () =>
      uniqueItems && buCode && oAuthToken && !isLoading
        ? getProductInformation(
            market,
            language,
            isOnline
              ? { code: market, type: "ru" }
              : { code: buCode, type: "sto" },
            uniqueItems,
            oAuthToken,
          )
        : Promise.reject(new Error("Missing list or token")),
    {
      enabled: !!uniqueItems && !!buCode && !!oAuthToken && !isLoading,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      select: (res) => createProductList(res, uniqueItems!),
      gcTime: Infinity,
      // since we allow sharing list between devices
      // we need to occassionally invalidate data
      staleTime: 10000, // ten seconds
      placeholderData: keepPreviousData,
      retry: 3,
    },
  )

  return {
    products: productsQuery.data,
    error: productsQuery.error,
    isLoading: productsQuery.isPending,
    isFetched: productsQuery.isFetched,
  }
}
