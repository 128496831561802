import { getStore } from "@/api/getStoreInformation"
import { useAuth } from "@/hooks/useAuth"
import { useLocale } from "@/hooks/useLocale"
import { useQueryWithErrorHandling } from "./useReactQuery"

export function useStoreInformationHook(buCode: string | null) {
  const { market, language } = useLocale()
  const { oAuthToken } = useAuth()

  const queryResult = useQueryWithErrorHandling(
    ["storeInfo", buCode],
    () =>
      buCode && market && language && oAuthToken
        ? getStore({
            storeNo: buCode,
            market,
            language,
            kongToken: oAuthToken,
          })
        : Promise.reject(new Error("Missing fields")),
    {
      enabled: !!buCode && !!market && !!language && !!oAuthToken,
      gcTime: Infinity,
      staleTime: Infinity,
      retry: 3,
    },
  )

  return {
    storeInfo: queryResult.data,
    isLoading: queryResult.isPending,
    error: queryResult.error,
  }
}

// This is needed for stub in cypress component tests
export const localeObject = { useStoreInformation: useStoreInformationHook }

export const useStoreInformation = (buCode: string | null) =>
  localeObject.useStoreInformation(buCode)
