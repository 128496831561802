import { AddProductButton } from "@/components/AddProductButton"
import { categorizeProducts } from "@/utils/categorizeProducts"
import { useEffect, useState } from "react"
import AppFooter from "./components/list/AppFooter"
import AppHeader from "./components/list/AppHeader"
import { HandoverModal } from "./components/modals/HandoverModal"
import DepartmentSection from "./components/sections/DepartmentSection"
import { FullServeSection } from "./components/sections/FullServeSection"
import OnlineSection from "./components/sections/OnlineSection"
import SelfServeSection from "./components/sections/SelfServeSection"
import { HIDE_HANDOVER_TO_SALJA } from "./config/constants"
import { useCanAddProducts } from "./hooks/featureFlags/useCanAddProducts"
import { useShortId } from "./hooks/useHandoverQuery"
import { useListig } from "./hooks/useListig"
import { useLocale } from "./hooks/useLocale"
import { useStoreInformation } from "./hooks/useStoreInformation"
import { TotalPriceReceipt } from "./components/list/TotalPriceReceipt"
import { EmptyListSection } from "./components/sections/EmptyListSection"
import { sendHandoverToSaljaEvent } from "./analytics/events/sendHandoverToSaljaEvent"
import { useLoadingSteps } from "./hooks/useLoadingSteps"
import LoadingView from "./views/LoadingView"

export const App: React.FC = () => {
  const productsQuery = useListig()
  const { storeInfo } = useStoreInformation(
    productsQuery.list?.businessUnit.code ?? null,
  )
  const { market } = useLocale()
  const [showHandoverModal, setShowHandoverModal] = useState(false)
  const shortId = useShortId()
  const canAddProducts = useCanAddProducts()
  const { steps } = useLoadingSteps()
  const { totalPrice } = productsQuery
  const productsInList = productsQuery.list?.items ?? []
  const orders = productsQuery.list?.orders ?? []
  const categorizedProducts = categorizeProducts(productsQuery.list)
  const listIsEmpty = !productsInList.length && !orders.length

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="w-screen h-screen flex flex-col">
      <div className="w-full bg-transparent flex flex-col flex-1">
        <AppHeader title={storeInfo?.displayName ?? "IKEA"} />
        <div className="flex flex-col flex-1 bg-gray-100">
          {categorizedProducts.FLOORS.length > 0 && (
            <>
              {categorizedProducts.FLOORS.map(
                (item, index) =>
                  !!item && (
                    <DepartmentSection
                      key={index}
                      type="FLOOR"
                      floor={index + ""}
                      products={item}
                    />
                  ),
              )}
            </>
          )}
          {categorizedProducts.CONTACT_COWORKER.length > 0 && (
            <DepartmentSection
              type="CONTACT_COWORKER"
              products={categorizedProducts.CONTACT_COWORKER}
            />
          )}
          {categorizedProducts.SHOWROOM.length > 0 && (
            <DepartmentSection
              type="SHOWROOM"
              products={categorizedProducts.SHOWROOM}
            />
          )}
          {categorizedProducts.MARKET_HALL.length > 0 && (
            <DepartmentSection
              type="MARKET_HALL"
              products={categorizedProducts.MARKET_HALL}
            />
          )}
          {categorizedProducts.SELF_SERVE.length > 0 && (
            <SelfServeSection products={categorizedProducts.SELF_SERVE} />
          )}
          {(categorizedProducts.FULL_SERVE.length > 0 || orders.length > 0) && (
            <FullServeSection products={categorizedProducts.FULL_SERVE} />
          )}
          {categorizedProducts.ONLINE.length > 0 && (
            <OnlineSection products={categorizedProducts.ONLINE} />
          )}

          {listIsEmpty && !productsQuery.isLoading && <EmptyListSection />}

          {productsQuery.isLoading && (
            <LoadingView type="linear" steps={steps} />
          )}

          {!listIsEmpty && !!totalPrice && (
            <div className="py-16 px-7">
              <TotalPriceReceipt
                currency={totalPrice.currency}
                familyPrice={totalPrice.family}
                totalPrice={totalPrice.regular}
                secondCurrency={totalPrice.secondCurrency}
                secondTotalPrice={totalPrice.secondaryPrice}
                secondFamily={totalPrice.secondFamily}
              />
            </div>
          )}
          <AppFooter
            onOptionsClick={() => {
              setShowHandoverModal(true)
              sendHandoverToSaljaEvent()
            }}
            className="px-7"
          />
        </div>
      </div>
      {canAddProducts && (
        <AddProductButton
          listIsEmpty={listIsEmpty}
          products={categorizedProducts.ONLINE}
        />
      )}
      {!HIDE_HANDOVER_TO_SALJA.includes(market?.toUpperCase() || "") &&
        shortId && (
          <HandoverModal
            showing={showHandoverModal}
            onClose={() => setShowHandoverModal(false)}
            value={shortId}
            backValue={shortId}
          />
        )}
    </div>
  )
}
