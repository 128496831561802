import { TSM_ENABLED, TSM_ENABLED_KIOSKS } from "@/config/constants"
import { useListig } from "@/hooks/useListig"
import { isEnabledWithConstraints } from "@/utils/isEnabled"
import { useIsomOrders } from "./useIsomOrders"
import { useCheckout } from "./useCheckout"
import { useAdminSettings } from "../useAdminSettings"

/**
 * @returns true if time slot management is enabled in kiosk/store/market or from params
 */
export function useTimeSlotManagement() {
  const { session } = useListig()
  const { settings } = useAdminSettings()
  const { source } = session
  const ukid = source?.type === "kiosk" ? source.ukid : undefined
  const isIsom = useIsomOrders()
  const isCheckout = useCheckout()

  return isEnabledWithConstraints({
    overrides: [TSM_ENABLED], // will be automatically enabled if tsm is enabled from url
    dependancies: [isIsom, isCheckout], // requires isom and checkout to be enabled
    locations: [
      !!settings?.isomRollout.enableTimeSlotManagement,
      !!ukid && TSM_ENABLED_KIOSKS.includes(ukid.toUpperCase()),
    ],
  })
}
