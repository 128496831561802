import { Locations } from "@/config/constants"

/** Returns whether the feature is enabled or not based on
 * market, businessunit and which kiosk the list came from. */
export const isEnabled = ({
  market,
  businessUnitCode,
  ukid,
  ENABLED,
  ENABLED_LOCATIONS,
}: {
  market?: string
  businessUnitCode?: string
  ukid?: string
  ENABLED: boolean
  ENABLED_LOCATIONS: Locations
}) => {
  const marketEnabled = !!market && ENABLED_LOCATIONS.markets.includes(market)

  const storeEnabled =
    !!businessUnitCode && ENABLED_LOCATIONS.stores.includes(businessUnitCode)

  const kioskEnabled = !!ukid && ENABLED_LOCATIONS.kiosks.includes(ukid)

  return ENABLED || marketEnabled || storeEnabled || kioskEnabled
}

/** Returns whether the feature is enabled or not based on
 * overrides, dependancies and enabled locations. */
export const isEnabledWithConstraints = ({
  overrides,
  dependancies,
  locations,
}: {
  overrides?: boolean[]
  dependancies?: boolean[]
  locations: boolean[]
}) => {
  const isOverrided = overrides?.some((e) => e) ?? false
  const hasAllDependancies = dependancies?.every((e) => e) ?? true
  const isEnabledInLocation = locations.some((e) => e)

  return isOverrided || (hasAllDependancies && isEnabledInLocation)
}
