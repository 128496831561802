import {
  CHECKOUT_ENABLED,
  ISOM_ORDERS_ENABLED_KIOSKS,
  TSM_ENABLED,
} from "@/config/constants"
import { useListig } from "@/hooks/useListig"
import { isEnabledWithConstraints } from "@/utils/isEnabled"
import { useAdminSettings } from "../useAdminSettings"

/**
 * @returns true if isom orders has been enabled for market/store/kiosk
 */
export function useIsomOrders() {
  const { session } = useListig()
  const { settings } = useAdminSettings()
  const { source } = session
  const ukid = source?.type === "kiosk" ? source.ukid : undefined

  return isEnabledWithConstraints({
    overrides: [TSM_ENABLED, CHECKOUT_ENABLED], // will be automatically enabled if tsm or checkout is enabled from url
    locations: [
      !!settings?.isomRollout.enableIsomOrders,
      !!ukid && ISOM_ORDERS_ENABLED_KIOSKS.includes(ukid.toUpperCase()),
    ],
  })
}
