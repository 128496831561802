import { sendKepsEvent } from "../keps"

/**
 * Sends the "[...]_handover_to_instore" event.
 * @param source the source of the handover, e.g "kiosk" for upptäcka kiosks
 * @param detail Eventdetail
 * @param setVisitCounter Function to update visit counter
 * @param ukid the ukid when using direct handover from upptäcka kiosks
 * @param kioskSessionId the sessionId when using direct handover from upptäcka kiosks
 * @param referrerListId The listId to merge from
 */
export async function sendStartSessionEvent(
  source: string,
  detail: string,
  setVisitCounter: () => void,
  ukid?: string,
  kioskSessionId?: string,
  referrerListId?: string,
  isFastTrack?: boolean,
) {
  setVisitCounter()

  sendKepsEvent({
    event_location: "list",
    event_name: "handover",
    event_detail: detail.toLocaleLowerCase().replaceAll("-", "_"),
    event_referrer: {
      referrer_id: source.toLocaleLowerCase().replaceAll("-", "_"),
      client_id: ukid,
    },
    event_payload: JSON.stringify({
      kiosk_session_id: kioskSessionId,
      referrer_list_id: referrerListId,
      is_fast_track: isFastTrack,
    }),
  })
}
