import {
  EDIT_ORDERS_ENABLED,
  EDIT_ORDERS_ENABLED_KIOSKS,
} from "@/config/constants"
import { useListig } from "@/hooks/useListig"
import { useIsomOrders } from "./useIsomOrders"
import { isEnabledWithConstraints } from "@/utils/isEnabled"
import { useAdminSettings } from "../useAdminSettings"

/**
 * @returns true if edit orders has been enabled for market/store/kiosk
 */
export function useEditOrders() {
  const { session } = useListig()
  const { settings } = useAdminSettings()
  const { source } = session
  const ukid = source?.type === "kiosk" ? source.ukid : undefined
  const isIsom = useIsomOrders()

  return isEnabledWithConstraints({
    overrides: [EDIT_ORDERS_ENABLED], // will be automatically enabled if edit is enabled from url
    dependancies: [isIsom], // requires isom to be enabled
    locations: [
      !!settings?.isomRollout.enableEditOrders,
      !!ukid && EDIT_ORDERS_ENABLED_KIOSKS.includes(ukid.toUpperCase()),
    ],
  })
}
