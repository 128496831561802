import { sendOpenPipEvent } from "@/analytics/events/sendOpenPipEvent"
import { useKortisId } from "./useKortis"
import { useLocalNavigation } from "./useLocalNavigation"
import { ProductType } from "@/types/product/products"

export function useNavigateHandover(
  market: string | undefined,
  language: string | undefined,
) {
  const { navigate } = useLocalNavigation()
  const { createShortIdParam } = useShortIdParam(market, language)

  return {
    navigateToPip: async (
      listId: string,
      productNo: string | undefined,
      productType: ProductType | undefined,
    ) => {
      const shortIdParam = await createShortIdParam(listId)

      if (productNo && productType) {
        sendOpenPipEvent(productNo, "scanner")
        navigate(
          {
            path: "product",
            productNo,
            productType,
            listId,
          },
          undefined,
          shortIdParam,
        )
      }
    },
    navigateToList: async (listId: string) => {
      const shortIdParam = await createShortIdParam(listId)

      navigate(
        {
          path: "list",
          listId,
        },
        { replace: true },
        shortIdParam,
      )
    },
  }
}

const useShortIdParam = (
  market: string | undefined,
  language: string | undefined,
) => {
  const { createKortisId } = useKortisId()

  return {
    createShortIdParam: async (listId: string) => {
      const kortisResponse =
        market && language
          ? await createKortisId(market, language, listId)
          : undefined

      return kortisResponse
        ? new URLSearchParams({ shortId: kortisResponse.shortId })
        : undefined
    },
  }
}
