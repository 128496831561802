import { createListigList } from "@/api/listig"
import { useParseHandover } from "@/hooks/useHandoverQuery"
import { EntryPoint } from "./useVisit"
import { useMutationWithErrorHandling } from "./useReactQuery"
import { useAuthAsync } from "./useAuth"

export const useCreateList = (entryPoint: EntryPoint) => {
  const { getAuthToken } = useAuthAsync()
  const { parseHandover } = useParseHandover()

  const mutation = useMutationWithErrorHandling(
    async (input: {
      market?: string
      language?: string
      isOnline: boolean
    }) => {
      const token = await getAuthToken()
      const handover = parseHandover(entryPoint)

      return input.market && input.language
        ? createListigList(
            "items" in handover && handover.items ? handover.items : [],
            input.market,
            input.language,
            { code: handover.storeNo, type: input.isOnline ? "ru" : "sto" },
            token,
            handover.source,
          )
        : Promise.reject(new Error("Missing fields"))
    },
  )

  return mutation.mutateAsync
}
