import { FullServeProduct } from "@/types/product/categorizedProduct"
import { sendKepsEvent } from "../keps"

export function sendStockProbabilityEvent(products: FullServeProduct[]) {
  sendKepsEvent({
    event_location: "list",
    event_name: "order_restriction",
    event_detail: "low_stock",
    event_items: products.map((product) => ({ item_no: product.info.no })),
    event_payload: JSON.stringify(
      products.map((product) => {
        return {
          item_no: product.info.no,
          probability: product.stock.cashCarry.probability?.thisDay ?? "",
          in_range: product.stock.cashCarry.inRange,
          stock: product.stock.cashCarry.quantity,
        }
      }),
    ),
  })
}
