import { sendKepsEvent } from "../keps"

export function sendOrderPickingStatusEvent(
  orderNo: string,
  orderNoSource: string,
  pickingStatus: string,
) {
  sendKepsEvent({
    event_location: "list",
    event_name: "order_picking_status",
    event_detail: pickingStatus.toLowerCase(),
    event_payload: JSON.stringify({
      orderNo,
      orderNoSource,
    }),
  })
}
