import { sendKepsEvent } from "../keps"

export function sendNonUpptackaOrderEvent(
  orderNo: string,
  orderSource: string,
) {
  sendKepsEvent({
    event_location: "order_page",
    event_name: "non_upptacka_order",
    event_payload: JSON.stringify({
      order_number: orderNo,
      order_source: orderSource,
    }),
  })
}
