import { ListSetup } from "@/components/ListSetup"
import { ReactElement } from "react"
import { Route, Routes } from "react-router"

export const SessionRouter: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  return (
    <Routes>
      <Route path="order" element={<ListSetup entryPoint="order" />} />
      <Route path="new" element={<ListSetup entryPoint="new" />} />
      <Route path="items" element={<ListSetup entryPoint="items" />} />
      <Route path="product" element={<ListSetup entryPoint="product" />} />
      <Route
        path="list/:listId/*"
        element={<ListSetup entryPoint="list">{children}</ListSetup>}
      />
      <Route path="*" element={children} />
    </Routes>
  )
}
