import { Route, Routes, useLocation } from "react-router-dom"
import { AnimatePresence, motion } from "framer-motion"
import { App } from "@/App"
import { useCanAddProducts } from "@/hooks/featureFlags/useCanAddProducts"
import { Add, Product, Welcome, ScannerInstructions } from "@/views"
import { Checkout } from "@/components/Checkout"

/**
 * Router for the apps main flow.
 */
export const AppRouter: React.FC = () => {
  const canAddProducts = useCanAddProducts()
  const location = useLocation()
  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={location.pathname}
        initial="hidden"
        animate="enter"
        exit="exit"
        variants={{
          hidden: { opacity: 0 },
          enter: { opacity: 1, transition: { duration: 0.1, ease: "easeOut" } },
          exit: { opacity: 0, transition: { duration: 0.3, ease: "easeIn" } },
        }}
      >
        <Routes location={location} key={location.pathname}>
          {canAddProducts && <Route path="add/*" element={<Add />} />}
          {canAddProducts && (
            <Route
              path="scanner-instructions"
              element={<ScannerInstructions />}
            />
          )}
          <Route path="product/:productNo" element={<Product />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="welcome" element={<Welcome />} />
          <Route path="*" element={<App />} />
        </Routes>
      </motion.div>
    </AnimatePresence>
  )
}
