import { useListig } from "@/hooks/useListig"
import { isFullServeOrOnlineProduct } from "@/types/product/categorizedProduct"
import { categorizeProducts } from "@/utils/categorizeProducts"
import { useEffect, useMemo, useState } from "react"
import { sendOrderPickingStatusEvent } from "@/analytics/events/sendOrderPickingStatusEvent"
import localStorage from "localstorage-slim"
import { calculatePriceTotals } from "@/utils/calculatePriceTotals"
import { useProducts } from "./useProducts"
import { parseCcOrder } from "@/utils/parseCcOrder"
import { getOrder } from "@/api/retrieveFullServeOrder"
import {
  CashCarryOrderResponse,
  CashCarryOrderCreatedPartialResponse,
} from "@/types/responses/buy"
import { OrderOutdatedError } from "@/utils/errors"
import { useAuth } from "./useAuth"
import { useCo360 } from "./featureFlags/useCo360"
import { useLocale } from "./useLocale"
import { useQueryWithErrorHandling } from "./useReactQuery"
import { useWaitingTime } from "./featureFlags/useWaitingTime"
import { getValidProductType } from "@/types/product/products"

const orderPickingStatusTimeToLive = 60 * 60 * 8
const EIGHT_HOURS = 28800000

export function useOrderInformation(orderNo: string, orderNoSource: string) {
  const listig = useListig()
  const session = listig.session
  const fullServeProducts = categorizeProducts(listig.list).FULL_SERVE
  const totalPrice = calculatePriceTotals(fullServeProducts)
  const { order, isLoading: isOrderLoading } = useCcOrderPolling(
    orderNo,
    orderNoSource,
  )

  useEffect(() => {
    if (!order || !("pickingService" in order)) return
    const pickingStatusCacheKey = order.orderNo + "-pickingstatus"
    const pickingStatus = localStorage.get(pickingStatusCacheKey)
    const newPickingStatus = order?.pickingService?.status

    if (newPickingStatus && newPickingStatus !== pickingStatus) {
      sendOrderPickingStatusEvent(
        order.orderNo,
        order.orderNoSource,
        newPickingStatus,
      )
      localStorage.set(pickingStatusCacheKey, newPickingStatus, {
        ttl: orderPickingStatusTimeToLive,
        encrypt: false,
      })
    }
  }, [order])

  const { products, isLoading: areProductsLoading } = useProducts(
    session.businessUnitCode,
    order && "orderStatus" in order
      ? order.articles.map((art) => ({
          id: art.itemId,
          type: getValidProductType(art.itemType),
          quantity: art.quantity,
        }))
      : undefined,
  )
  const items = products?.filter(isFullServeOrOnlineProduct)

  const instoreOrder = order
    ? parseCcOrder(order, items, totalPrice)
    : undefined

  return {
    isOrderLoading: isOrderLoading || (order && areProductsLoading),
    order: instoreOrder,
  }
}

function useCcOrderPolling(
  orderNo: string,
  orderNoSource: string,
): {
  order?: CashCarryOrderResponse | CashCarryOrderCreatedPartialResponse
  isOrderOutdated: boolean
  isLoading: boolean
} {
  const { market, language } = useLocale()
  const { oAuthToken } = useAuth()
  const { list } = useListig()
  const isCo360Enabled = useCo360()
  const waitingTimeEnabled = useWaitingTime()
  const [isOrderOutdated, setIsOrderOutdated] = useState<boolean>(false)
  const [orderCreatedAt, setOrderCreatedAt] = useState<number | undefined>(
    undefined,
  )

  // We set the endPollingTime to below options, in the following presedence, and adds EIGHT_HOURS to it:
  //  - lastUpdateAt from the list
  //  - Date.now()
  const endPollingTime = useMemo(() => {
    const referenceTime =
      orderCreatedAt ??
      (list?.metadata.lastUpdateAt
        ? new Date(list.metadata.lastUpdateAt).getTime()
        : Date.now())
    return referenceTime + EIGHT_HOURS
  }, [list?.metadata.lastUpdateAt, orderCreatedAt])

  const [isCompleted, setIsCompleted] = useState(false)

  const hasAllQueryParameters =
    !!orderNo && !!orderNoSource && !!language && !!market && !!oAuthToken

  const shouldFetch = !!orderNo

  const { data, isPending } = useQueryWithErrorHandling<
    CashCarryOrderResponse | CashCarryOrderCreatedPartialResponse
  >(
    ["order status", orderNo],
    () =>
      // test endPollingTime inside the fetch function since it should be
      // tested just before the next fetch and not after the last one
      endPollingTime < Date.now()
        ? Promise.reject(new OrderOutdatedError()).finally(() =>
            setIsOrderOutdated(true),
          )
        : hasAllQueryParameters
          ? getOrder({
              orderNo,
              orderNoSource,
              language,
              market,
              kongToken: oAuthToken,
              isCo360Enabled,
              showTimeEstimate: waitingTimeEnabled ?? false,
            })
          : Promise.reject(new Error("Missing fields")),
    {
      enabled: shouldFetch && !isCompleted && hasAllQueryParameters,
      refetchInterval: 10000,
      staleTime: 10000,
      gcTime: 60000,
      retry: 3,
    },
    "warning",
  )

  useEffect(() => {
    if (data && "orderCreationDate" in data) {
      setOrderCreatedAt(new Date(data.orderCreationDate).getTime())
    }
  }, [data])

  useEffect(() => {
    if (data) {
      setIsCompleted(orderIsCompleted(data))
    }
  }, [data])

  return {
    order: data,
    isOrderOutdated: isOrderOutdated,
    // isLoading should be false until we start fetching
    isLoading: isPending && shouldFetch,
  }
}

function orderIsCompleted(
  order: CashCarryOrderResponse | CashCarryOrderCreatedPartialResponse,
) {
  if (!("orderStatus" in order)) return false

  return order.pickingService?.status
    ? order.pickingService.status === "COMPLETED"
    : order.orderStatus === "COMPLETED"
}
