import { useIsOnline } from "@/hooks/useIsOnline"
import { Price as PriceSchema } from "@/types/responses/find"
import { useTranslation } from "react-i18next"
import { Price } from "../common/Price"
import { useListig } from "@/hooks/useListig"

export type TotalPriceReceiptProps = {
  totalPrice: PriceSchema
  familyPrice?: PriceSchema
  currency: string
  secondCurrency?: string
  secondTotalPrice?: PriceSchema
  secondFamily?: PriceSchema
}

export const TotalPriceReceipt: React.FC<TotalPriceReceiptProps> = ({
  totalPrice,
  familyPrice,
  currency,
  secondCurrency,
  secondTotalPrice,
  secondFamily,
}) => {
  const { t } = useTranslation()
  const { session } = useListig()
  const { isOnline } = useIsOnline(session.businessUnitCode ?? null)

  const { exclTax: priceExclTax, tax } = familyPrice ?? totalPrice

  const { exclTax: secPriceExclTax, tax: secTax } =
    secondFamily ?? secondTotalPrice ?? {}

  return (
    <div className="flex flex-col justify-between text-xl">
      {!!tax && (
        <>
          <div className="flex w-full justify-between text-gray-500">
            <p className="text-sm">{t("ShoppingListTotals-subtotals")}</p>
            <Price
              size="small"
              price={priceExclTax}
              currency={currency}
              className="pt-1 text-sm font-normal text-gray-500"
              secondCurrency={secondCurrency}
              secondPrice={secPriceExclTax}
              secondClassName="pt-1 text-xs font-normal text-gray-500"
            />
          </div>
          <div className="mt-1 flex w-full justify-between text-gray-500">
            <p className="text-sm">{t("ShoppingListTotals-VAT")}</p>
            <Price
              size="small"
              price={tax}
              currency={currency}
              className="pt-1 text-sm font-normal text-gray-500"
              secondCurrency={secondCurrency}
              secondPrice={secTax}
              secondClassName="pt-1 text-xs font-normal text-gray-500"
            />
          </div>
          {isOnline && (
            <div className="mt-1 flex w-full justify-between text-gray-500">
              <p className="text-sm">
                {`${t("AvailabilityCard-delivery")}/${t("common.collect")}`}
              </p>
              <p className="text-sm text-right">
                {t("mobile.calculated-at-checkout")}
              </p>
            </div>
          )}
        </>
      )}

      <span className="my-4 h-1 w-full border-t-2 border-solid border-black" />

      {familyPrice ? (
        <>
          <div
            className="flex w-full justify-between"
            data-cy="total-price-family"
          >
            <h4 className="text-blue-brand">
              {t("ProductIkeaFamilyPriceBadge-badge")}
            </h4>
            <Price
              size="medium"
              price={familyPrice.inclTax}
              currency={currency}
              className="pt-1 text-blue-brand"
              secondCurrency={secondCurrency}
              secondPrice={secondFamily?.inclTax}
              secondClassName="pt-1 text-xl text-blue-brand"
            />
          </div>
          <div className="mt-4 flex w-full justify-between text-gray-500">
            <p className="text-sm ">{t("ShoppingListTotals-regular-price")}</p>
            <Price
              size="small"
              price={totalPrice.inclTax}
              currency={currency}
              className="pt-1 font-normal text-gray-500"
              secondCurrency={secondCurrency}
              secondPrice={secondTotalPrice?.inclTax}
              secondClassName="pt-1 text-xs font-normal text-gray-500"
            />
          </div>
        </>
      ) : (
        <div className="flex w-full justify-between" data-cy="total-price">
          <h4>{t("ShoppingListTotals-total")}</h4>
          <Price
            size="medium"
            price={totalPrice.inclTax}
            currency={currency}
            secondCurrency={secondCurrency}
            secondPrice={secondTotalPrice?.inclTax}
            secondClassName="text-xl"
          />
        </div>
      )}
      <p className="mt-4 text-xs text-gray-400">
        {t("ShoppingList-modal-disclaimer")}
      </p>
    </div>
  )
}
