import { getActiveList, setActiveList } from "@/utils/localStorage"
import { useListId } from "./useHandoverQuery"
import { useListigQuery } from "./useListigQuery"
import { isActive } from "@/utils/listig"
import { useAddProducts } from "./useAddProducts"
import { ListigItem, ListigList, ListigOrder } from "@/types/listig"
import { useAddOrders } from "./useAddOrder"
import { useDeleteList } from "./useDeleteList"
import { useSearchParams } from "react-router"
import { useState } from "react"

export const useMergeLists = () => {
  const [completed, setCompleted] = useState(false)
  const [searchParams] = useSearchParams()
  const orderFastTrack = searchParams.get("orderFastTrack")

  const { deleteList } = useDeleteList()

  const listIdFromUrl = useListId()?.listId
  const listFromUrl = useListigQuery(listIdFromUrl)

  const listIdFromLocalStorage = getActiveList()
  const listFromLocalStorage = useListigQuery(listIdFromLocalStorage)

  const moveOrdersToActiveList = useMoveOrdersToActiveList()
  const moveProductsToActiveList = useMoveProductsToActiveList(
    listIdFromLocalStorage,
  )

  return {
    completed,
    loading: !!(
      (listIdFromUrl && (listFromUrl.isLoading || listFromUrl.isFetching)) ||
      (listIdFromLocalStorage &&
        (listFromLocalStorage.isLoading || listFromLocalStorage.isFetching))
    ),
    mergeLists: async ({
      navigate,
      createNewList,
      itemsToMoveToActiveList,
      ordersToMoveToActiveList,
      handoverStoreNo,
    }: {
      navigate: (listId: string) => Promise<void>
      createNewList?: () => Promise<ListigList>
      itemsToMoveToActiveList?: ListigItem[]
      ordersToMoveToActiveList?: ListigOrder[]
      handoverStoreNo: string | undefined
    }) => {
      const listIdsAreTheSame = listIdFromUrl === listIdFromLocalStorage
      const activeListExistsInLocalStorage = hasActiveListInLocalStorage(
        handoverStoreNo,
        listFromLocalStorage.list,
      )

      const SHOULD_DO_NOTHING = !!(
        listIdFromUrl &&
        (listIdsAreTheSame || !activeListExistsInLocalStorage)
      )
      const SHOULD_CREATE_LIST =
        !activeListExistsInLocalStorage && !listIdFromUrl
      const SHOULD_MERGE = activeListExistsInLocalStorage && !listIdsAreTheSame

      switch (true) {
        case SHOULD_DO_NOTHING:
          setCompleted(true)

          if (orderFastTrack) {
            navigate(listIdFromUrl)
          }
          break

        case SHOULD_CREATE_LIST:
          createNewList?.().then(async (list) => {
            await moveOrdersToActiveList(list, ordersToMoveToActiveList)

            setActiveList(list.id, list.metadata.createdAt)
            navigate(list.id).then(() => setCompleted(true))
          })
          break

        case SHOULD_MERGE:
          await Promise.all([
            moveProductsToActiveList(
              listIdFromUrl,
              listFromUrl.list,
              itemsToMoveToActiveList,
            ),
            moveOrdersToActiveList(
              listFromLocalStorage.list,
              ordersToMoveToActiveList,
            ),
          ])

          if (listIdFromUrl) {
            await deleteList(listIdFromUrl)
          }

          navigate(listIdFromLocalStorage ?? "").then(() => setCompleted(true))
          break
      }

      /**
       * If the list from local storage is not active and the list form the url is active,
       * we set the list from the url as the new active list in local storage.
       */
      const newActiveList = listFromUrl.list
      if (!activeListExistsInLocalStorage && newActiveList) {
        setActiveList(newActiveList.id, newActiveList.metadata.createdAt)
      }
    },
  }
}

const hasActiveListInLocalStorage = (
  handoverStoreNo: string | undefined,
  listFromLocalStorage: ListigList | undefined,
) => {
  const listsAreInSameStore =
    !handoverStoreNo ||
    !listFromLocalStorage ||
    listFromLocalStorage.businessUnit.code === handoverStoreNo

  return isActive(listFromLocalStorage) && listsAreInSameStore
}

const useMoveProductsToActiveList = (activeListId: string | undefined) => {
  const addProducts = useAddProducts(activeListId ?? null)

  return async (
    listIdFromUrl: string | undefined,
    listFromUrl: ListigList | undefined,
    itemsToMoveToActiveList: ListigItem[] | undefined,
  ) => {
    const listIsNotMergedBefore = listIdFromUrl && isActive(listFromUrl)

    const shouldMoveProducts = !listIdFromUrl || listIsNotMergedBefore

    if (shouldMoveProducts) {
      await addProducts.add(itemsToMoveToActiveList ?? [])
    }
  }
}

const useMoveOrdersToActiveList = () => {
  const addOrders = useAddOrders()

  return async (
    activeList: ListigList | undefined,
    orders: ListigOrder[] | undefined,
  ) => {
    const ordersInActiveList = activeList?.orders?.map((order) => order.orderNo)

    const ordersToMoveToActiveList = orders?.filter(
      (orderToMove) => !ordersInActiveList?.includes(orderToMove.orderNo),
    )

    if (ordersToMoveToActiveList?.length) {
      await addOrders.add(
        activeList?.id ?? null,
        ordersToMoveToActiveList,
        false,
      )
    }
  }
}
