import { APP_PROMOTION_DISABLED_MARKETS } from "@/config/constants"
import { useLocale } from "@/hooks/useLocale"
import { useAdminSettings } from "../useAdminSettings"

/**
 * @returns true if app promotion or shop&go handover has not been disabled for the market
 */
export function useAppPromotion() {
  const { market } = useLocale()
  const { settings } = useAdminSettings()
  const appHandoverEnabled =
    !!settings?.handover.handoverOptions.includes("Shop & Go")

  return !APP_PROMOTION_DISABLED_MARKETS.includes(market) && appHandoverEnabled
}
